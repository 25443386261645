/* eslint-disable no-undef */
import React, { useState, useEffect, lazy, Suspense } from 'react';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import Spinner from 'react-bootstrap/Spinner';
import { GlobalStyle } from 'beem-component';
import { isUndefined } from 'lodash';
import { HashRouter, Redirect, Switch } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { IntlProvider } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from 'styled-components';
import 'react-circular-progressbar/dist/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import { getThemeColor, saveToken, getUserId } from './client';
import { LocaleProvider, ConfigProvider, AppProvider } from './providers';
import { UnifiedContext, UnifiedProvider } from './unified-provider';
import { LocaleContext } from './configs';
import messages from './localeData.json';
import './assets/font-icons/style.css';
import './main.scss';
import './assets/css/host.scss';
import { useReactPath } from './util';
import FavIcon from './assets/images/favicon.ico';
import SecureRoute from './app/components/SecureRoute';
import tracker from './app/GA-Tracking';

const Dashboard = lazy(() => import('./app/Dashboard'));

const history = createBrowserHistory();
history.listen(() => {
  tracker.setField(window.location.hash);
  tracker.sendPageView(window.location.hash);
});

const App = () => {
  const [isLoading, setLoading] = useState(false);
  const [tokenProcessed, setTokenProcessed] = useState(
    !isUndefined(Cookies.get('token'))
  );
  const parsed = queryString.parse(window.location.search); // eslint-disable-line
  const { path, defaults } = useReactPath();

  useEffect(() => {
    if (!isEmpty(parsed.token)) {
      setLoading(true);
      saveToken(parsed.token, parsed.client_id)
        .then(() => {
          setLoading(false);
          setTokenProcessed(true);
          window.location.replace(`/#!/?token=${parsed.token}`); // eslint-disable-line
        })
        .catch(() => {
          setLoading(false);
          setTokenProcessed(false);
        });
    }
  }, []);

  // Favicons for sms and easyapps
  const getFaviconEl = () => {
    return document.getElementById('favicon');
  };

  const location = window.location.hostname;
  useEffect(() => {
    const favicon = getFaviconEl();

    if (!location.includes('easyapps')) {
      favicon.href = FavIcon;
    } else {
      favicon.remove();
    }
  }, [location]);
  ReactGA.set({
    userId: getUserId(),
  });

  return isLoading ? (
    <div className="d-flex align-items-center justify-content-center my-2">
      <Spinner as="span" animation="border" role="status" aria-hidden="false" />
    </div>
  ) : (
    <Suspense
      fallback={
        <div className="d-flex align-items-center justify-content-center my-2">
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="false"
          />
        </div>
      }
    >
      <ToastProvider placement="top-center" autoDismiss>
        <AppProvider
          path={path}
          defaults={defaults}
          authorized={tokenProcessed}
        >
          <ThemeProvider theme={{ color: getThemeColor() }} autoDismiss>
            <UnifiedProvider authorized={tokenProcessed}>
              <GlobalStyle />
              <LocaleProvider>
                <LocaleContext.Consumer>
                  {(context) => (
                    <IntlProvider
                      locale={context.locale}
                      key={context.locale}
                      messages={messages[context.locale]}
                    >
                      <UnifiedContext.Consumer>
                        {(app) => (
                          <ConfigProvider
                            path={path}
                            defaults={defaults}
                            app={app.options.defaultApp}
                            emgUser={app.emgUser}
                            authorized={tokenProcessed}
                          >
                            <HashRouter hashType="hashbang" history={history}>
                              <Switch>
                                <SecureRoute
                                  path="/dashboard"
                                  component={Dashboard}
                                />
                                <Redirect to="/dashboard" />
                              </Switch>
                            </HashRouter>
                          </ConfigProvider>
                        )}
                      </UnifiedContext.Consumer>
                    </IntlProvider>
                  )}
                </LocaleContext.Consumer>
              </LocaleProvider>
            </UnifiedProvider>
          </ThemeProvider>
        </AppProvider>
      </ToastProvider>
    </Suspense>
  );
};

export default App;
