/* eslint-disable class-methods-use-this */
import ReactGA from 'react-ga4';

class GoogleAnalyticsTracker {
  constructor() {
    this.tracking_id = process.env.REACT_APP_TRACKING_ID;
    this.initializeTracker(this.tracking_id);
  }

  initializeTracker = (measurementId) => {
    ReactGA.initialize(`${measurementId}`);
  };

  setField = (pageName) => {
    ReactGA.set({ page_title: `${pageName}` });
  };

  sendPageView = (pagePath) => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: 'pageview', page: `${pagePath}` });
  };

  sendEvent = (eventObject) => {
    // Send a custom event
    ReactGA.event({
      category: `${eventObject.category}`,
      action: `${eventObject.action}`,
      label: `${eventObject.label}`,
    });
  };
}

const tracker = new GoogleAnalyticsTracker();
Object.freeze(tracker);
export default tracker;
