/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/*
 * collection of helper/ utility functions
 */
import React from 'react';
import moment from 'moment';
import map from 'lodash/map';
import merge from 'lodash/merge';
import range from 'lodash/range';
import camelCase from 'lodash/camelCase';
import reverse from 'lodash/reverse';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import head from 'lodash/head';
import pick from 'lodash/pick';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { DATE_TIME_FORMAT, USER_ROLES, DATE_FORMAT_DOB } from './configs';
import {
  getUserId,
  getRole,
  httpActions,
  getCountries,
  getNetworks,
  getRoutes,
  getNetworkRoutes,
  getPaymentMethods,
  getRoles,
  getAdminNumbers,
  getDefaultCurrency,
  getDefaultCountry,
  getWhatsappTemplates,
  getCampaignSenderNames2,
  getWhatsappChannels,
} from './client';

const {
  getSenderNames,
  getVendors,
  getGenericTemplates,
  getTemplateCategories,
} = httpActions;

// TODO add documentation for each functions

export const formatDate = (date, format = DATE_TIME_FORMAT) => {
  return moment(date).format(format);
};

export const formatDateOnly = (date, format = DATE_FORMAT_DOB) => {
  return moment(date).format(format);
};

export const formatCurrency = () => {};

export const getMonthDaysOptions = (month, year) => {
  const shortMonths = [4, 6, 9, 11];
  if (month === 2 && year % 4 === 0) {
    return map(range(1, 30), (day) => ({ label: day, value: day }));
  }

  if (month === 2 && year % 4 !== 0) {
    return map(range(1, 29), (day) => ({ label: day, value: day }));
  }

  if (shortMonths.includes(month)) {
    return map(range(1, 31), (day) => ({ label: day, value: day }));
  }

  return map(range(1, 32), (day) => ({ label: day, value: day }));
};

export const getYearsOptions = (startYear = 1940, endYear = 2010) =>
  map(reverse(range(startYear, endYear)), (year) => ({
    label: year,
    value: year,
  }));

export const prepareContacts = ({ headers, data, hasHeader }) => {
  let rows = [...data];

  if (hasHeader) {
    rows = rows.slice(1);
  }

  return map(rows, (row) => {
    const temp = map(headers, (header) => {
      return { [header.value]: row[header.position] };
    });

    return merge({}, ...temp);
  });
};

export const formatContacts = (data) => {
  // so data is an array of objects
  const contacts = [];
  data.forEach((object) => {
    const singleArray = [];
    for (const key in object) {
      if (Object.hasOwnProperty.call(object, key)) {
        const element = object[key];
        singleArray.push(element);
      }
    }
    contacts.push(singleArray);
  });
  return contacts;
};

/**
 * @function
 * @name calcRatioValue
 * @description Calculate ratio of value over total
 * @param {number} total total count
 * @param {number} value value to calculate percentage from
 *
 * @returns {number} ratio value/total
 * @version 0.2.0
 * @since 0.1.0
 */
export const calcRatioValue = (total, value) => {
  if (parseInt(total, 10) === 0) {
    return 0;
  }

  return value / total;
};

/**
 * @function
 * @name calcPercentage
 * @description Calculate percentage for a ratio
 * @param {number} total total count
 * @param {number} value value to calculate percentage from
 *
 * @returns {number} percentage
 * @version 0.2.0
 * @since 0.1.0
 */
export const calcPercentage = (total, value) => {
  return (calcRatioValue(total, value) * 100).toFixed(1);
};

/**
 * @function
 * @name camelize
 * @description Joins names and generate camelCase of joined words them
 *
 * @param {...string} words list of words to join and camelize
 * @returns {string} camelCase of joined words
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const camelize = (...words) => {
  return camelCase([...words].join(' '));
};

export const broadcastBuildParams = (isAdmin, isReseller) => {
  if (isReseller) {
    return { user_id: getUserId(), app_name: 'BROADCAST' };
  }

  if (isAdmin) {
    return {};
  }

  return { user_id: getUserId(), app_name: 'BROADCAST' };
};

const intGsm7bitExMap = () => {
  return [
    91, 92, 93, 94, 123, 124, 125, 126, 128, 8364, 10, 916, 934, 915, 232, 923,
    937, 928, 236, 936, 931, 199, 920, 926, 216, 196, 228, 248, 198, 214, 230,
    209, 197, 223, 220, 229, 201, 224,
  ];
};
const utf8ToUnicode = (str) => {
  const unicode = [];
  let values = [];
  let lookingFor = 1;

  for (let i = 0; i < str.length; i++) {
    const thisValue = str.charCodeAt(i);
    // TODO: MINOR TWEAK TO ALLOW SOME CHARACTERS TO BE COUNTED TWICE
    if (intGsm7bitExMap().indexOf(str.charCodeAt(i)) > -1) {
      unicode.push(thisValue);
    }
    if (thisValue < 128 || thisValue === 8364 || thisValue === 10) {
      unicode.push(thisValue);
    } else {
      if (values.length === 0) {
        lookingFor = thisValue < 224 ? 2 : 3;
      }
      values.push(thisValue);
      if (values.length === lookingFor) {
        const number =
          lookingFor === 3
            ? (values[0] % 16) * 4096 + (values[1] % 64) * 64 + (values[2] % 64)
            : (values[0] % 32) * 64 + (values[1] % 64);
        unicode.push(number);
        values = [];
        lookingFor = 1;
      } // if
    } // if
  } // for
  return unicode;
};

const intGsm7bitMap = () => {
  return [
    96, 10, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
    49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67,
    68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86,
    87, 88, 89, 90, 92, 95, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107,
    108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122,
    161, 163, 164, 165, 191, 196, 197, 198, 199, 201, 209, 214, 216, 220, 223,
    224, 228, 229, 230, 232, 233, 236, 241, 242, 246, 248, 249, 252, 915, 916,
    920, 923, 926, 928, 931, 934, 936, 937,
  ];
};

const arrayDiff = (array1, array2) => {
  const temp = [];
  array1 = array1.toString().split(',').map(Number);
  array2 = array2.toString().split(',').map(Number);
  for (const i in array1) {
    if (array2.indexOf(array1[i]) === -1) temp.push(array1[i]);
  }
  for (const i in array2) {
    if (array1.indexOf(array2[i]) === -1) temp.push(array2[i]);
  }
  return temp.sort((a, b) => a - b);
};

const intGsm7bitCombinedMap = () => {
  return intGsm7bitMap().concat(intGsm7bitExMap());
};

const detectEncoding = (text, exChars) => {
  if (!Array.isArray(text)) {
    text = utf8ToUnicode(text);
  }
  // TODO: REMOVE ! IN THE !arrayDiff(text, intGsm7bitCombinedMap())
  const utf16Chars = !arrayDiff(text, intGsm7bitCombinedMap());
  if (utf16Chars.length > 0) {
    return 'UTF16';
  }
  exChars = text.filter((value) => intGsm7bitExMap().indexOf(value) !== -1);
  if (exChars.length > 0) {
    return 'GSM_7BIT_EX';
  }
  return 'GSM_7BIT';
};

const smsCount = (str) => {
  const unicodeArray = utf8ToUnicode(str);
  // variable to catch if any ex chars while encoding detection.
  const exChars = [];
  const encoding = detectEncoding(unicodeArray, exChars);
  let { length } = unicodeArray;
  if (encoding === 'GSM_7BIT_EX') {
    const lengthExchars = exChars.length;
    length += lengthExchars;
  }
  const messageLengthGSM7BIT = 160;
  const multiMessageLengthGSM7BIT = 153;
  const messageLengthGSM7BITEX = 160;
  const multiMessageLengthGSM7BITEX = 153;
  const messageLengthUTF16 = 70;
  const multiMessageLengthUTF16 = 67;
  let perMessage;
  switch (encoding) {
    case 'GSM_7BIT':
      if (length > messageLengthGSM7BIT) {
        perMessage = multiMessageLengthGSM7BIT;
      } else {
        perMessage = messageLengthGSM7BIT;
      }
      break;
    case 'GSM_7BIT_EX':
      if (length > messageLengthGSM7BITEX) {
        perMessage = multiMessageLengthGSM7BITEX;
      } else {
        perMessage = messageLengthGSM7BITEX; // 8bit
      }
      break;
    default:
      if (length > messageLengthUTF16) {
        // unicode 16bit
        perMessage = multiMessageLengthUTF16;
      } else {
        perMessage = messageLengthUTF16;
      }
      break;
  }
  const messages = Math.ceil(length / perMessage);
  const remaining = perMessage * messages - length;
  const returnset = {
    encoding,
    msg_length: length,
    perMessage,
    remaining,
    msg_count: messages,
  };
  return returnset;
};

export const checkUnicode = (utf8String) => {
  const gsm0338 = [
    '@',
    'Δ',
    ' ',
    '0',
    '¡',
    'P',
    '¿',
    'p',
    '£',
    '_',
    '!',
    '1',
    'A',
    'Q',
    'a',
    'q',
    '$',
    'Φ',
    '"',
    '2',
    'B',
    'R',
    'b',
    'r',
    '¥',
    'Γ',
    '#',
    '3',
    'C',
    'S',
    'c',
    's',
    'è',
    'Λ',
    '¤',
    '4',
    'D',
    'T',
    'd',
    't',
    'é',
    'Ω',
    '%',
    '5',
    'E',
    'U',
    'e',
    'u',
    'ù',
    'Π',
    '&',
    '6',
    'F',
    'V',
    'f',
    'v',
    'ì',
    'Ψ',
    "'",
    '7',
    'G',
    'W',
    'g',
    'w',
    'ò',
    'Σ',
    '(',
    '8',
    'H',
    'X',
    'h',
    'x',
    'Ç',
    'Θ',
    ')',
    '9',
    'I',
    'Y',
    'i',
    'y',
    '\n',
    'Ξ',
    '*',
    ':',
    'J',
    'Z',
    'j',
    'z',
    'Ø',
    '\x1B',
    '+',
    ';',
    'K',
    'Ä',
    'k',
    'ä',
    'ø',
    'Æ',
    ',',
    '<',
    'L',
    'Ö',
    'l',
    'ö',
    '\r',
    'æ',
    '-',
    '=',
    'M',
    'Ñ',
    'm',
    'ñ',
    'Å',
    'ß',
    '.',
    '>',
    'N',
    'Ü',
    'n',
    'ü',
    'å',
    'É',
    '/',
    '?',
    'O',
    '§',
    'o',
    'à',
    // Additional
    '|',
    '[',
    ']',
    '{',
    '}',
    '\\',
    '^',
    '~',
    '€',
  ];

  const len = utf8String.length;
  const arr = false;

  for (let i = 0; i < len; i++) {
    if (!gsm0338.includes(utf8String.substr(i, 1, 'UTF-8')) && !arr) {
      return true;
    }
  }
  return false;
};

/**
 * @function
 * @name isReseller
 * @description Check if current logged in user has a reseller role
 *
 * @returns {boolean} return true is user has a reseller role
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const isReseller = () => getRole() === 'reseller';

/**
 * @function
 * @name isAdmin
 * @description Check if the current logged in user has an admin role
 *
 * @returns {boolean} return true is user has admin role
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const isAdmin = () => getRole() === 'admin';

/**
 * @function
 * @name isVendor
 * @description Check if the current logged in user has a vendor role
 *
 * @returns {boolean} return true is user has vendor role
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const isVendor = () => getRole() === 'vendor';

/**
 * @function
 * @name isSubaccount
 * @description Check if the current logged in user has a sub-account role
 *
 * @returns {boolean} return true is user is a sub-account
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const isSubaccount = () => getRole() === 'subaccount';

/**
 * @function
 * @name isSales
 * @description Check if the current logged in user has a sales role
 *
 * @returns {boolean} return true is user is a sales
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const isSales = () => getRole() === 'sales';

/**
 * @function
 * @name isSalesAdmin
 * @description Check if the current logged in user has a salesadmin role
 *
 * @returns {boolean} return true is user is a salesadmin
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const isSalesAdmin = () => getRole() === 'salesadmin';

/**
 * @function
 * @name isAccounts
 * @description Check if the current logged in user has an accounts role
 *
 * @returns {boolean} return true is user has accounts role
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const isAccounts = () => getRole() === 'accounts';

/**
 * @function
 * @name isSupport
 * @description Check if the current logged in user has support role
 *
 * @returns {boolean} return true is user has support role
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const isSupport = () => getRole() === 'support';

/**
 * @function
 * @name isTechnical
 * @description Check if the current logged in user has technical role
 *
 * @returns {boolean} return true is user has technical role
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const isTechnical = () => getRole() === 'technical';

/**
 * @function
 * @name isSenderid
 * @description Check if the current logged in user has senderid role
 *
 * @returns {boolean} return true is user has senderid role
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const isSenderid = () => getRole() === 'senderid';

/**
 * @function
 * @name isInAdminGroup
 * @description Check if user belongs to admin group
 *
 * @returns {boolean} true if user has on of these roles; admin,sales,accounts,
 * support, technical, senderid
 * @version 0.1.0
 * @since 0.1.0
 */
export const isInAdminGroup = () => {
  return [
    'admin',
    'sales',
    'accounts',
    'support',
    'technical',
    'senderid',
    'salesadmin',
  ].includes(getRole());
};

/**
 * @function
 * @name isNull
 * @description Check value if is null especially that from api or local storage
 * @param {string} value input value
 *
 * @returns {boolean} true if value is null or "null"
 * @version 0.1.0
 * @since 0.1.0
 */
export const isNull = (value) => isEmpty(value) || value === 'null';

/**
 * @function
 * @name getSenderNamesOptions
 * @description Sender name options for async react select component
 *
 * @param {string} inputValues search query
 * @param {Function} callback function to be called with options
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const getSenderNamesOptions = (inputValues, callback) => {
  getSenderNames({ q: inputValues, vendor_id: getUserId(), status: 'active' })
    .then((results) => {
      const options = map(results.data, (senderName) => ({
        value: senderName.id,
        label: senderName.senderid,
      }));

      callback(options);
    })
    .catch(() => callback([]));
};

/**
 * @function
 * @name getUnverifiedSenderNamesOptions
 * @description Sender name options for async react select component
 *
 * @param {string} inputValues search query
 * @param {Function} callback function to be called with options
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const getUnverifiedSenderNamesOptions = (inputValues, callback) => {
  getSenderNames({ q: inputValues })
    .then((results) => {
      const options = map(results.data, (senderName) => ({
        value: senderName.senderid,
        label: senderName.senderid,
      }));

      callback(options);
    })
    .catch(() => callback([]));
};

export const getWhatsappTemplatesOptions = (inputValues, callback) => {
  getWhatsappTemplates({ q: inputValues, status: 'enabled' })
    .then((results) => {
      const options = map(
        filter(
          results.data,
          (template) => template.facebook_template_id && template.template_id
        ),
        (template) => ({
          value: template.template_id,
          label: template.name,
          data: template,
        })
      );

      callback(options);
    })
    .catch(() => callback([]));
};

export const getQuickWhatsappTemplatesOptions = (inputValues, callback) => {
  getWhatsappTemplates({ q: inputValues, status: 'enabled', variables: true })
    .then((results) => {
      const options = map(
        filter(
          results.data,
          (template) => template.facebook_template_id && template.template_id
        ),
        (template) => ({
          value: template.template_id,
          label: template.name,
          data: template,
        })
      );

      callback(options);
    })
    .catch(() => callback([]));
};

export const getGenericSMSTemplatesOptions = (inputValues, callback) => {
  getGenericTemplates()
    .then((results) => {
      const options = map(results.data, (template) => ({
        value: template.id,
        label: template.category,
        message: template.message,
      }));
      callback(options);
    })
    .catch(() => callback([]));
};

export const getGenericSMSTemplatesCategories = (inputValues, callback) => {
  getTemplateCategories()
    .then((results) => {
      const options = map(results.data, (template) => ({
        value: template.id,
        label: template.category,
      }));
      callback(options);
    })
    .catch(() => callback([]));
};

// eslint-disable-next-line func-style
function filterWhatsappActive(data) {
  return data.filter(
    (item) =>
      item.channel === 'whatsapp' &&
      item.active === 1 &&
      item.api_username !== null
  );
}
export const getActiveWhatsappChannels = (inputValues, callback) => {
  getWhatsappChannels({ q: inputValues, active: 1 })
    .then((results) => {
      const filteredData = filterWhatsappActive(results.data);
      const options = map(filteredData, (template) => ({
        value: template.chat_channel_number,
        label: `${template.chat_channel_number} (${template.chat_channel_name})`,
      }));
      callback(options);
    })
    .catch(() => callback([]));
};

/**
 * @function
 * @name getCountriesOptions
 * @description Create react select options for all countries
 * @param {string} inputValue String value for searching countries
 * @param {Function} callback Injected callback by Async react select
 * @returns {undefined}
 * @version 0.1.0
 * @since 0.1.0
 */
export const getCountriesOptions = (inputValue, callback) => {
  getCountries({ q: inputValue })
    .then((results) => {
      const options = map(results.data, (country) => ({
        value: country.country,
        label: country.country,
      }));
      callback(options);
    })
    .catch(() => callback([]));
};

/**
 * @function
 * @name getRolesOptions
 * @description Create react select options for all roles
 * @param {string} inputValue String value for searching roles
 * @param {Function} callback Injected callback by Async react select
 * @returns {undefined}
 * @version 0.1.0
 * @since 0.1.0
 */
export const getRolesOptions = (inputValue, callback) => {
  getRoles({ q: inputValue })
    .then((results) => {
      const options = map(results.data, (role) => ({
        value: role.id,
        label: role.roleName,
      }));
      callback(options);
    })
    .catch(() => callback([]));
};

export const getCountriesOption = (inputValue, callback) => {
  getCountries({ q: inputValue })
    .then((results) => {
      const options = map(results.data, (country) => ({
        value: country,
        label: country.country,
      }));
      callback(options);
    })
    .catch(() => callback([]));
};
/**
 * @function
 * @name loadNetworkOptions
 * @description Create react select options for all networks
 * @param {string} inputValue String value for searching networks
 * @param {Function} callback Injected callback by Async react select
 * @returns {undefined}
 * @version 0.1.0
 * @since 0.1.0
 */
export const loadNetworkOptions = (inputValue, callback) => {
  getNetworks({ q: inputValue })
    .then((results) => {
      const options = map(results.data, (network) => ({
        value: network.id,
        label: network.name,
      }));

      callback(options);
    })
    .catch(() => callback([]));
};

/**
 * @function
 * @name loadRoutesOptions
 * @description Create react select options for all routes
 * @param {string} inputValue String value for searching routes
 * @param {Function} callback Injected callback by Async react select
 * @returns {undefined}
 * @version 0.1.0
 * @since 0.1.0
 */
export const loadRoutesOptions = (inputValue, callback) => {
  getRoutes({ q: inputValue })
    .then((results) => {
      const options = map(results.data, (route) => ({
        value: route.route_name,
        label: route.route_name,
      }));

      callback(options);
    })
    .catch(() => callback([]));
};

/**
 * @function
 * @name loadNetworkRoutesOptions
 * @description Create react select options for all network routes
 * @param {string} inputValue String value for searching network routes
 * @param {Function} callback Injected callback by Async react select
 * @returns {undefined}
 * @version 0.1.0
 * @since 0.1.0
 */
export const loadNetworkRoutesOptions = (inputValue, callback) => {
  getNetworkRoutes({ q: inputValue })
    .then((results) => {
      const options = map(results.data, (route) => ({
        value: route.id,
        label: `${route.route_name}-${route['networks.name']}${
          route.default_route ? ' Default' : ''
        }`,
      }));

      callback(options);
    })
    .catch(() => callback([]));
};

/**
 * @function
 * @name loadVendorOptions
 * @description Create react select options for all system vendors including sub accounts
 * @param {string} inputValue String value for searching vendors
 * @param {Function} callback Injected callback by Async react select
 * @returns {undefined}
 * @version 0.1.0
 * @since 0.1.0
 */
export const loadVendorOptions = (inputValue, callback) => {
  getVendors({ q: inputValue })
    .then((results) => {
      const options = map(results.data, (vendor) => ({
        value: vendor.id,
        label: `${vendor.parent_ven_id ? '[SUB-ACCOUNT] ' : ''}${
          vendor.first_name
        } ${vendor.last_name}(${vendor.username})`,
      }));

      callback(options);
    })
    .catch(() => callback([]));
};

/**
 * @function
 * @name loadVendorOptions
 * @description Create react select options for sub-accounts for current
 * logged in vendor including sub accounts
 * @param {string} inputValue String value for searching vendors
 * @param {Function} callback Injected callback by Async react select
 * @returns {undefined}
 * @version 0.1.0
 * @since 0.1.0
 */
export const loadSubAccountOptions = (inputValue, callback) => {
  getVendors({ q: inputValue, parent_ven_id: getUserId() })
    .then((results) => {
      const options = map(results.data, (vendor) => ({
        value: vendor.id,
        label: `${vendor.first_name} ${vendor.last_name} (${vendor.username})`,
        username: vendor.username,
        role: vendor.userrole,
        email: vendor.email,
      }));
      callback(options);
    })
    .catch(() => callback([]));
};

/**
 * @function
 * @name loadVendorOptions
 * @description Create react select options for sub-accounts for current
 * logged in vendor including sub accounts
 * @param {string} inputValue String value for searching vendors
 * @param {Function} callback Injected callback by Async react select
 * @returns {undefined}
 * @version 0.1.0
 * @since 0.1.0
 */
export const loadTwoWayOptions = (inputValue, callback) => {
  getAdminNumbers({ q: inputValue })
    .then((results) => {
      const options = map(results.data, (twoway) => ({
        value: twoway.id,
        label: twoway.number,
      }));
      callback(options);
    })
    .catch(() => callback([]));
};

/**
 * @function
 * @name loadPaymentMethodOptions
 * @description Create react select options for payment options from API
 * @param {string} inputValue String value for searching payment methods
 * @param {Function} callback Injected callback by Async react select
 * @returns {undefined}
 * @version 0.1.0
 * @since 0.1.0
 */
export const loadPaymentMethodOptions = (inputValue, callback) => {
  getPaymentMethods({
    q: inputValue,
    active: 1,
    currency: getDefaultCurrency(),
    country: getDefaultCountry(),
    app_name: 'BROADCAST',
  })
    .then((results) => {
      const options = results.data.map((method) => {
        let label;

        if (method.type === 'Stripe') {
          label = 'Visa/Mastercard';
        } else if (method.type === 'DPO-Instant') {
          label = 'DPO Mobile Money';
        } else {
          label = method.type; // Use the default label if not matched
        }

        return {
          value: method.type,
          label,
        };
      });

      callback(options);
    })
    .catch(() => callback([]));
};

export const loadUserPaymentMethodOptions = (inputValue, callback) => {
  getPaymentMethods({
    q: inputValue,
    active: 1,
    app_name: 'BROADCAST',
  })
    .then((results) => {
      const options = map(results.data, (payment) => ({
        value: payment.type,
        label: isInAdminGroup()
          ? `${payment.type} - ${payment.currency}`
          : payment.type,
        method: payment.method,
        currency: payment.currency,
      }));

      callback(options);
    })
    .catch(() => callback([]));
};
/**
 * @function
 * @name getUserRolesOptions
 * @description Generate dynamic user roles options based on current user role
 * This allow admin admin to make others admin while others can make users
 * sub accounts and resellers
 *
 * @returns {object[]} return options
 * @version 0.1.0
 * @since 0.1.0
 */
export const getUserRolesOptions = () => {
  const roles = isAdmin()
    ? USER_ROLES
    : pick(USER_ROLES, ['subaccount', 'reseller', 'vendor']);
  return map(roles, (value, key) => {
    return {
      value: key,
      label: value,
    };
  });
};

/**
 * @function
 * @name getSMSRoleOptions
 * @description Create react select options for all roles
 * @param {string} inputValue String value for searching roles
 * @param {Function} callback Injected callback by Async react select
 * @returns {undefined}
 * @version 0.1.0
 * @since 0.1.0
 */
export const getSMSRoleOptions = (inputValue, callback) => {
  getRoles({ q: inputValue })
    .then((results) => {
      const options = map(results.data, (role) => ({
        value: role.roleName,
        label: role.roleName,
        code: role.id,
      }));
      callback(options);
    })
    .catch(() => callback([]));
};

/**
 * @function
 * @name isValidPhoneNumber
 * @description
 * @param {string} phoneNumber Input value from the user for phone number
 *
 * @returns {boolean} true if phone is valid phone
 * @version 0.1.0
 * @since 0.1.0
 */
export const isValidPhoneNumber = (phoneNumber) => {
  let phone = phoneNumber.toString();

  const format = /[!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?]+/; // eslint-disable-line

  if (format.test(phone)) {
    return false;
  }

  try {
    if (phone.match(/^0/)) {
      phone = phone.replace(/^0/, 255);
    }

    const phoneObject = parsePhoneNumberFromString(
      phone.match(/^\+/) ? phone : `+${phone}`
    );

    return phoneObject.isValid();
  } catch (error) {
    return false;
  }
};

/**
 * @function
 * @name truncateString
 * @description truncates string
 * @param {string} str  string to truncate
 * @param {number} num number of characters to not exceed
 * @returns {string} truncated string
 * @version 0.1.0
 * @since 0.1.0
 */
export const truncateString = (str = '', num) => {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.

  if (!str) {
    return '';
  }

  if (str.length <= num) {
    return str;
  }
  // Return str truncated with '...' concatenated to the end of str.
  return `${str.slice(0, num)}...`;
};

export const renderStringWithNewLine = (str) => {
  const strings = compact(str.split('\n'));

  return map(strings, (string) => <p>{string}</p>);
};

/**
 * @function
 * @name insertString
 * @description Insert string into another string at a given position
 * @param {string} currentString Main string
 * @param {string} insertedString String to be inserted
 * @param {number} position Position to insert string
 * @returns {string} newly updated string
 * @version 0.1.0
 * @since 0.1.0
 */
export const insertString = (currentString, insertedString, position) => {
  return `${currentString.slice(
    0,
    position
  )} {${insertedString}} ${currentString.slice(position)}`;
};

/**
 * @function
 * @name getAllowedStartDate
 * @description Generate allowed start date based on modules
 * @param {object[]} roles A collection of map of role and days
 * @returns {Date|undefined} minimum allowed start date or undefined
 * @version 0.1.0
 * @since 0.1.0
 */
export const getAllowedStartDate = (roles) => {
  const role = filter(roles, { role: getRole() });
  if (!isEmpty(role)) {
    return new Date(moment().subtract(head(role).days, 'days').calendar());
  }
  return undefined;
};

export const getAllowedDate = () => {
  return new Date(moment().subtract(1, 'years').calendar());
};
/**
 * @function
 * @name getLocaleConfigs
 * @param {string} locale current set locale
 * @param {object} configs configs
 * @returns {object|Array} Return configs per particular locale
 * @version 0.1.0
 * @since 0.1.0
 */
export const getLocaleConfigs = (locale, configs) => {
  return configs[locale];
};

/**
 * @function
 * @name calcMessageCount
 * @description calculate the length of the message
 * @param {string} message Message text string
 *
 * @returns {object} Map for length and message count
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const calcMessageCount = (message = '') => {
  const charCount = smsCount(message);
  return {
    count: charCount.msg_count,
    length: charCount.msg_length,
  };
};

export const useReactPath = () => {
  const initialPath = React.useState(window.location.hash.split('/')[2]);

  const defaultPath = React.useState(window.location.hash.split('/')[3]);

  const [defaults, setDefaultPath] = defaultPath;

  const [path, setPath] =
    initialPath && initialPath === 'multi-sms' ? 'emg' : initialPath;
  const listenToPopstate = () => {
    const winPath = window.location.hash.split('/')[2];
    const defaultPaths = window.location.hash.split('/')[3];

    setPath(winPath && winPath === 'multi-sms' ? 'emg' : winPath);
    setDefaultPath(defaultPaths);
  };
  React.useEffect(() => {
    window.addEventListener('popstate', listenToPopstate);
    return () => {
      window.removeEventListener('popstate', listenToPopstate);
    };
  }, []);
  return { path, defaults };
};

export const isValidMsisdn = (msisdn) => {
  if (msisdn !== undefined) {
    msisdn = msisdn.toString();
    try {
      const phoneObj = parsePhoneNumberFromString(
        msisdn.match(/^\+/) ? msisdn : `+${msisdn}`
      );
      return phoneObj.isValid();
    } catch (err) {
      return false;
    }
  }
};

export const trimMsisdn = (msisdn) => {
  if (msisdn !== undefined) {
    msisdn = msisdn
      .toString()
      .toString()
      .replace(/\s+/g, '')
      .replace(/-/g, '')
      .replace(/\^/g, '')
      .replace(/_/g, '')
      .replace(/[#_+-]/g, '')
      .replace(/[{()}]/g, '')
      .replace(/[#_+-]/g, '');
    const dn = msisdn.match(/^\+/) ? msisdn : `+${msisdn}`;
    const PhoneNumber = parsePhoneNumberFromString(dn);
    if (PhoneNumber) {
      return msisdn;
    }
  }

  // return msisdn;
};

export const paymentName = (paymentMethod) => {
  if (paymentMethod.type === 'Stripe') return 'Visa/Mastercard';
  if (paymentMethod.type === 'DPO-Instant') return 'DPO Mobile Money';
  return paymentMethod.type;
};

export const getPaymentMethodLabel = (paymentMethodType) => {
  if (paymentMethodType === 'Stripe') {
    return 'Visa/Mastercard';
  }
  if (paymentMethodType === 'DPO-Instant') {
    return 'DPO Mobile Money';
  }
  return paymentMethodType; // Default label if not matched
};

export const getCampaignSenderNamesOptions = (inputValues, callback) => {
  getCampaignSenderNames2({
    q: inputValues,
    vendor_id: getUserId(),
    // status: 'active',
  })
    .then((results) => {
      const options = map(results.data, (senderName) => ({
        value: senderName.id,
        label: senderName.senderid,
        status: senderName.status,
      }));

      if (options.length > 0) {
        const uniqueOptions = options.filter(
          (option, index, self) =>
            self.findIndex((o) => o.label === option.label) === index
        );
        callback(uniqueOptions);
      } else {
        callback(options);
      }
    })
    .catch(() => callback([]));
};

export const isValidSenderName = (senderName) => {
  const regex = /^(?!.*\/\/)[A-Za-z0-9_][A-Za-z0-9_. -]*$/;

  if (!regex.test(senderName)) {
    return false;
  }
  return senderName;
};
