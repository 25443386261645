/* eslint-disable eqeqeq */
/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import Cookies from 'js-cookie';
import { LocaleContext, ConfigsContext, AppContext } from './configs';
import {
  getLocale,
  getAccountBalance,
  getUserId,
  getLoginDetails,
} from './client/client';
import CallbackRequest from './app/components/Callback';

// declarations
const defaultLocale = getLocale() || 'en';

export const LocaleProvider = ({ children }) => {
  const [locale, setLocale] = useState(defaultLocale);

  return (
    <LocaleContext.Provider
      value={{
        locale,
        changeLocale: (newLocale) => setLocale(newLocale),
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

export const ConfigProvider = ({
  children,
  path,
  app,
  emgUser,
  defaults,
  authorized,
}) => {
  const { addToast } = useToasts();
  const [credits, setCredits] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [shouldReload, setShouldReload] = useState(0);

  useEffect(() => {
    if (authorized) {
      setLoading(true);
      getAccountBalance(
        { vendor_id: getUserId() },
        path,
        app,
        emgUser,
        defaults
      )
        .then((response) => {
          setLoading(false);
          setCredits(response.data.credit_balance || response.data.credit_bal);
        })
        .catch((error) => {
          setLoading(false);
          addToast(error.message, { appearance: 'error' });
        });
    }
  }, [addToast, shouldReload, path, emgUser, authorized]);

  return (
    <ConfigsContext.Provider
      value={{
        credits,
        isLoading,
        refreshBalance: () => setShouldReload(shouldReload + 1),
        path,
        defaults,
      }}
    >
      {children}
    </ConfigsContext.Provider>
  );
};

export const AppProvider = ({ path, defaults, authorized, children }) => {
  const login_attempts = getLoginDetails();
  const [attempts, setAttempts] = useState(login_attempts);
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (authorized) {
      setLoading(false);
      setError(false);
      if (login_attempts) {
        login_attempts.map((el) => {
          if (path && path === el.app_name.toLowerCase() && el.attempts < 1) {
            setLoading(true);
            setError(false);
            CallbackRequest({ app_name: el.app_name })
              .then(() => {
                const objIndex = login_attempts.findIndex(
                  (obj) => obj.app_name.toLowerCase() === path
                );
                login_attempts[objIndex].attempts++;
                Cookies.set('attempts', login_attempts);
                setLoading(false);
                setAttempts(login_attempts);
                setError(false);
              })
              .catch(() => {
                setLoading(false);
                setError(true);
              });
          }
        });
      }
    }
  }, [path]);

  return (
    <AppContext.Provider value={{ error, path, defaults, attempts, isLoading }}>
      {children}
    </AppContext.Provider>
  );
};

ConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
