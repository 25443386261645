import React, { useState, useContext, useCallback, useEffect } from 'react';
import { getDefaultApp, getLoginDetails, getUserById } from './client/client';

const attempts = getLoginDetails();
const defaultApp = getDefaultApp();

const defaultOptions = {
  attempts,
  smsGoal: false,
  defaultApp,
};
export const UnifiedContext = React.createContext();

export const UnifiedProvider = ({ children, engage, authorized }) => {
  const [currentOptions, setCurrentOptions] = useState(
    engage || defaultOptions
  );
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [user, setUser] = useState('inactive');

  const saveOption = (values) => {
    setLoading(true);
    setCurrentOptions(values);
    setLoading(false);
  };

  useEffect(async () => {
    if (authorized) {
      setLoading(true);
      const response = await getUserById();
      setUser(response.data.emg_status);
      setLoading(false);
    }
  }, []);

  const refresh = useCallback(
    () => setShouldRefresh(!shouldRefresh),
    [shouldRefresh]
  );

  return (
    <UnifiedContext.Provider
      value={{
        options: currentOptions,
        saveOption,
        refresh,
        isLoading,
        emgUser: user,
      }}
    >
      {children}
    </UnifiedContext.Provider>
  );
};

export const unifiedContext = () => useContext(UnifiedContext);
