/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';
import * as singleSpa from 'single-spa';
import App from './App';
import * as serviceWorker from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

if (
  process.env.REACT_APP_AUTH_REDIRECT_URL !== 'https://dev-login.beem.africa'
) {
  Sentry.init({
    dsn: 'https://fd476b39ec6bec3f497f9c9a26ce0260@o430675.ingest.us.sentry.io/4506863349268480',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
  });
}

ReactGA.initialize('UA-73095540-4');

const render = (Component) =>
  ReactDOM.render(<Component />, document.getElementById('root')); // eslint-disable-line

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default; // eslint-disable-line global-require
    render(NextApp);
  });
}

singleSpa.registerApplication({
  name: 'sms',
  app: () => window.System.import('@beem/sms'),
  activeWhen: '/#!/dashboard/sms',
  customProps: {
    token: Cookies.get('token'),
  },
});

singleSpa.registerApplication({
  name: 'airtime',
  app: () => window.System.import('@beem/airtime'),
  activeWhen: '/#!/dashboard/airtime',
  customProps: {
    token: Cookies.get('token'),
  },
});

singleSpa.registerApplication({
  name: 'otp',
  app: () => window.System.import('@beem/otp'),
  activeWhen: '/#!/dashboard/otp',
  customProps: {
    token: Cookies.get('token'),
  },
});

singleSpa.registerApplication({
  name: 'ussd',
  app: () => window.System.import('@beem/ussd'),
  activeWhen: '/#!/dashboard/ussd',
  customProps: {
    token: Cookies.get('token'),
  },
});

singleSpa.registerApplication({
  name: 'bpay',
  app: () => window.System.import('@beem/bpay'),
  activeWhen: '/#!/dashboard/bpay',
  customProps: {
    token: Cookies.get('token'),
  },
});

singleSpa.registerApplication({
  name: 'tagging',
  app: () => window.System.import('@beem/tagging'),
  activeWhen: '/#!/dashboard/tags',
  customProps: {
    token: Cookies.get('token'),
  },
});

singleSpa.registerApplication({
  name: 'emg',
  app: () => window.System.import('@beem/emg'),
  activeWhen: '/#!/dashboard/multi-sms',
  customProps: {
    token: Cookies.get('token'),
  },
});

// TODO: handle error messages
singleSpa.addErrorHandler((err) => {
  if (
    singleSpa.getAppStatus(err.appOrParcelName) ===
    (singleSpa.LOAD_ERROR ||
      singleSpa.LOADING_SOURCE_CODE ||
      singleSpa.NOT_LOADED)
  ) {
    console.log({ err });
    // window.System.delete(window.System.resolve(err.appOrParcelName));
  }
});

singleSpa.start();

serviceWorker.register();

reportWebVitals();
