/* eslint-disable no-unused-vars */
import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isValidUser } from '../../client';

/**
 * @function
 * @name AuthPage
 * @description Page for authentication for login page
 *
 * @returns {null} Nothing
 *
 * @version 0.1.0
 * @since 0.1.0
 */
const AuthPage = () => {
  // eslint-disable-next-line
  window.location.replace(process.env.REACT_APP_AUTH_REDIRECT_URL);
  return null;
};

const SecureRoute = ({ component: Component, data, location, ...rest }) => {
  const isAuthenticated = isValidUser();

  // const isAuthenticated = true; // TODO: Remove after testing
  /* eslint-disable */

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} {...data} /> : <AuthPage />
      }
    />
  );
  /* eslint-enable */
};

/* props validation */
SecureRoute.propTypes = {
  // component: PropTypes.oneOfType([
  //   PropTypes.func,
  //   PropTypes.element,
  //   PropTypes.node,
  // ]).isRequired,
  data: PropTypes.objectOf({
    vendor: PropTypes.object,
    loading: PropTypes.bool,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({ token: PropTypes.string }),
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

SecureRoute.defaultProps = {
  data: undefined,
};

export default withRouter(SecureRoute);
