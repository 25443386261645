/* eslint-disable no-unused-vars */
import { postCallbackApp } from '../../client';

const CallbackRequest = ({ app_name }) => {
  return postCallbackApp({ app_name })
    .then(() => {
      return true;
    })
    .catch((error) => {
      throw error;
    });
};

export default CallbackRequest;
